<template>
  <v-data-table
    :headers="headers"
    :items="stock_employees"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <router-link :to="{ name:'pages-stock-employee-create' }">
          <v-btn
            class="primary"
          >สร้างใบเบิก
          </v-btn>
        </router-link>

        <v-dialog
          v-model="dialog"
          max-width="1200"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                    <v-card>
                      <v-card-text>
                        <h3>เลขที่: {{ editedItem.no_stock }}</h3>
                        <!--                        <h3>วันที่: {{ editedItem.created_at ? substr(editedItem.created_at,0,10) : '-' }}</h3>-->
                        <h3>วันที่: {{ editedItem.created_at ? editedItem.created_at : '-' }}</h3>
                        <h3>ชื่อผู้ขอเบิก/พนักงาน:
                          {{ editedItem.employee ? editedItem.employee.employee_name : '-' }}
                        </h3>
                        <h3>สถานะ/พนักงาน:
                          <span v-if="editedItem.status =='Y'"
                                class="badge badge-success">เบิกเเล้ว</span>
                          <span v-if="editedItem.status =='N'" class="btn btn-warning btn-sm">รอการดำเนินการเบิก</span>
                          <span v-if="editedItem.status !='Y' && editedItem.status !='N'"
                                class="badge badge-danger">ยกเลิกเรียบร้อย</span>
                        </h3>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    md="6"
                  >
                    <v-card>
                      <v-card-text>
                        <v-text-field
                          class="text-center"
                          v-model="productCode" id="productCode"
                          placeholder="สแกนรหัสสินค้า"
                          label="สแกนรหัสสินค้า"
                          @blur="search_product"
                          @keyup.enter="search_product"
                        >

                        </v-text-field>
                        <!--                        v-on:blur="search" v-on:keyup.enter="$event.target.blur()"-->
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-card>
                      <v-card-text>
                        <table class="table">
                          <thead>
                          <tr>
                            <!--                            <th>เช็ครายการ</th>-->
                            <th class="text-center">#</th>
                            <th class="text-center">รหัส</th>
                            <th>รายการ</th>
                            <th>หน่วย</th>
                            <th>ราคา</th>
                            <th>มูลค่า(บาท)</th>
                            <th class="text-center">จำนวนเบิก</th>
                            <th class="text-center">จำนวนหยิบ</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr :id="index+'_row'"
                              :class="stock_employee.amount == stock_employee.amountCheck ? 'tr-success' :'tr-danger'"
                              style="color: black"
                              v-for="(stock_employee,index) in editedItem.stock_employees">
                            <!--                            <td>-->
                            <!--                              <i v-if="stock_employee.amount == stock_employee.amountCheck"-->
                            <!--                                 style="color: white;"-->
                            <!--                                 class="fa fa-check-circle-o fa-3x" aria-hidden="true"></i>-->
                            <!--                              <i v-if="stock_employee.amount != stock_employee.amountCheck"-->
                            <!--                                 style="color: red"-->
                            <!--                                 class="fa fa-times fa-3x" aria-hidden="true"></i>-->
                            <!--                            </td>-->
                            <td>{{ index + 1 }}
                            </td>
                            <td>{{
                                stock_employee.product ? stock_employee.product.product_code : ''
                              }}
                            </td>
                            <td>{{
                                stock_employee.product ? stock_employee.product.product_name : ''
                              }}
                            </td>
                            <td>ชิ้น</td>
                            <td>
                              <h6>
                                <!--                    @if(-->
                                <!--                    isset(auth()->user()->role()['all']) && auth()->user()->role()['all'] ||-->
                                <!--                    isset(auth()->user()->role()['view_product_price']) && auth()->user()->role()['view_product_price'])-->
                                {{ stock_employee.product ? stock_employee.product.product_costs : '' }}
                                <!--                    @else-->
                                <!--                    - -->
                                <!--                    @endif-->
                              </h6>
                            </td>
                            <td>
                              <h6>
                                <!--                    @if(-->
                                <!--                    isset(auth()->user()->role()['all']) && auth()->user()->role()['all'] ||-->
                                <!--                    isset(auth()->user()->role()['view_product_price']) && auth()->user()->role()['view_product_price'])-->
                                {{ stock_employee.product ? stock_employee.product.product_costs * stock_employee.amount : 0 }}
                                <!--                    @else-->
                                <!--                    - -->
                                <!--                    @endif-->
                              </h6>
                            </td>
                            <td>
                              <!--                          <input type="number" v-model="stock_employee.amount"-->
                              <!--                                 class="form-control" min="0" style="width: 100px" disabled>-->
                              <v-text-field type="number"
                                            v-model="stock_employee.amount"
                                            disabled
                                            class="form-control" min="0"
                                            style="width: 100px"></v-text-field>
                            </td>
                            <td>
                              <v-text-field type="number"
                                            @change="changAmount(index,stock_employee.amountCheck)"
                                            v-model="stock_employee.amountCheck"
                                            class="form-control" min="0"
                                            :max="stock_employee.amount"
                                            style="width: 100px"></v-text-field>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </v-card-text>
                    </v-card>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>
          </v-card>

        </v-dialog>

        <v-dialog
          v-model="dialogDelete"
          max-width="600"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
    </template>
    <template v-slot:item.status="{ item }">
      <div v-if="item.status == 'Y'">
        <router-link :to="{ name : 'pages-stock-employee-edit',params :{ id : item.id }}"
                     class="mr-2 already-opened"
        >
          เบิกแล้ว
        </router-link>

        <v-btn
          @click="cancel(item.id)"
          class="danger-badge">
          ยกเลิกคืนยอด
        </v-btn>
      </div>
      <div v-if="item.status == 'N'">
        <router-link :to="{ name : 'pages-stock-employee-edit',params :{ id : item.id }}"
                     class="mr-5">
          รอดำเนินการเบิก
        </router-link>
      </div>
      <div v-if="item.status != 'Y' && item.status != 'N'">
        <span class="cancel-status mr-2">ยกเลิกเรียบร้อย</span>
        <span class="success-badge"> ปรับคืนยอดเรียบร้อย</span>
      </div>


    </template>
    <template v-slot:item.checkBill="{ item }">
      <!--      <router-link-->
      <!--        style="text-decoration: none"-->
      <!--        target="_blank"-->
      <!--        :to="{ name : 'pages-stock-employee-check'}">-->
      <v-btn
        @click="checkBill(item)"
        color="secondary"
      >เช็คใบเบิก
      </v-btn>
      <!--      </router-link>-->
      <!--      <router-link>-->
      <!--        <v-icon-->
      <!--          small-->
      <!--          class="mr-2"-->
      <!--          @click="editItem(item)"-->
      <!--        >-->
      <!--          {{ icons.mdiPencil }}-->
      <!--        </v-icon>-->
      <!--      </router-link>-->
    </template>
    <template v-slot:item.actions="{ item }">
      <!--      '<a disabled="1"  target="_blank" href="print_stock_employee/' + id +'"> <i class="fa fa-print" aria-hidden="true"></i></a>&nbsp;&nbsp;' +-->

      <v-icon
        small
        class="mr-2"
        @click="print(item)"
      >
        {{ icons.mdiPrinter }}
      </v-icon>
      <v-icon
        small
        class="mr-2"
        @click="editItem(item.id)"
      >
        {{ icons.mdiPencil }}
      </v-icon>


      <span v-if="item.status == 'N'">
        <v-icon
          small
          @click="deleteItem(item)"
        >
          {{ icons.mdiDelete }}
        </v-icon>
      </span>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import {mdiPencil, mdiDelete, mdiPrinter} from '@mdi/js'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_stock_employee from '../../../services/stockEmokoyee'
import Swal from "sweetalert2";

export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
        mdiPrinter
      },
    }
  },
  data: () => ({
    serverOptions: {
      page: 1,
      itemsPerPage: 10,
    },
    total: 0,
    stock_employees: [],
    dialog: false,
    productCode: '',
    dialogDelete: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false
      },
      {text: 'เลขที่ใบเบิกสินค้า', value: 'no_stock'},
      {text: 'ชื่อพนักงานขอเบิก', value: 'employee.employee_name', sortable: false},
      {text: 'วันที่ขอเบิก', value: 'created_at'},
      {text: 'ดำเนินการโดย', value: 'employee_admin.employee_name', sortable: false},
      {text: 'สถานะ', value: 'status'},
      {text: 'เช็คใบเบิก', value: 'checkBill', sortable: false},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      employee_type_name: '',
      employee_type_comment: '',
      stock_employees: [],
    },
    defaultItem: {
      employee_type_name: '',
      employee_type_comment: '',
      stock_employees: [],
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'รายการเบิกสินค้า' : 'รายการเบิกสินค้า'
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getStockEmployee()
      },
    },
    search: {
      handler() {
        this.getStockEmployee()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getStockEmployee()
  },

  methods: {

    print(item) {
      var url = `/print_stock_employee/${item.id}`
      window.open(url, '_blank', 'noreferrer');
    },
    search_product() {
      let checkNo = true;
      if (this.productCode) {
        Object.entries(this.editedItem.stock_employees).forEach(([key, val]) => {
          if (!val.amountCheck) {
            val.amountCheck = 0;
          }
          val.amountCheck = parseInt(val.amountCheck)
          if (val.product.product_code === this.productCode) {
            val.amountCheck = val.amountCheck + 1;
            if (val.amountCheck != val.amount) {
              console.log(val,111)
              Swal.fire({
                icon: 'warning',
                title: 'รายการไม่เท่ากัน ขาด ' + parseInt(val.amount - val.amountCheck) + ' ชิ้น',
                text: ' รหัสสินค้า ' + val.product.product_code + ' ' + val.product.product_name + ' จำนวนที่ต้องยิบคือ  ' + val.amount + ' ชิ้น',
                showConfirmButton: false,
                timer: 1500
              })
              // swal({
              //   title: 'รายการไม่เท่ากัน ขาด ' + parseInt(val.amount - val.amountCheck) + ' ชิ้น',
              //   text: ' รหัสสินค้า ' + val.product_code + ' ' + val.name + 'จำนวนที่ต้องยิบคือ  ' + val.amount + ' ชิ้น',
              //   icon: 'warning',
              //   showConfirmButton: false,
              //   timer: 3500
              // })
            }
            if (val.amountCheck === val.amount) {

              Swal.fire({
                icon: 'success',
                title: 'รายการเท่ากันเเล้ว',
                text: '' + ' รหัสสินค้า ' + val.product.product_code + ' ' + val.product.product_name,
                showConfirmButton: false,
                timer: 1500
              })
            }
            checkNo = false;
            return false;
          }
        });

        if (checkNo) {

          Swal.fire({
            icon: 'error',
            title: ' รหัสสินค้า ' + this.productCode + ' ไม่พบในรายการ',
            text: ' รหัสสินค้า ' + this.productCode + ' ไม่พบในรายการ',
            showConfirmButton: false,
            timer: 1500
          })
        }
        this.productCode = '';
        document.getElementById("productCode").focus();
      }
    },
    changAmount(i, amount) {
      if (!this.editedItem.stock_employees[i].amountCheck && this.editedItem.stock_employees[i].amountCheck != 0) {
        this.editedItem.stock_employees[i].amountCheck = 0;
      }
      this.editedItem.stock_employees[i].amountCheck = parseInt(amount);

      if (this.editedItem.stock_employees[i].amountCheck != this.editedItem.stock_employees[i].amount) {
        document.getElementById(i + '_row').classList.remove("tr-success");
        document.getElementById(i + '_row').classList.add("tr-danger");
        Swal.fire({
          icon: 'warning',
          title: 'รายการไม่เท่ากัน ขาด ' + parseInt(this.editedItem.stock_employees[i].amount - this.editedItem.stock_employees[i].amountCheck) + ' ชิ้น',
          text: ' รหัสสินค้า ' + this.editedItem.stock_employees[i].product_code + ' ' + this.editedItem.stock_employees[i].name + 'จำนวนที่ต้องยิบคือ  ' + this.editedItem.stock_employees[i].amount + ' ชิ้น',
          showConfirmButton: false,
          timer: 1500
        })
        // swal({
        //   title: 'รายการไม่เท่ากัน ขาด ' + parseInt(val.amount - val.amountCheck) + ' ชิ้น',
        //   text: ' รหัสสินค้า ' + val.product_code + ' ' + val.name + 'จำนวนที่ต้องยิบคือ  ' + val.amount + ' ชิ้น',
        //   icon: 'warning',
        //   showConfirmButton: false,
        //   timer: 3500
        // })
      } else if (this.editedItem.stock_employees[i].amountCheck === this.editedItem.stock_employees[i].amount) {
        document.getElementById(i + '_row').classList.remove("tr-danger");
        document.getElementById(i + '_row').classList.add("tr-success");
        Swal.fire({
          icon: 'success',
          title: 'รายการเท่ากันเเล้ว',
          text: '' + ' รหัสสินค้า ' + this.editedItem.stock_employees[i].product.product_code + ' ' + this.editedItem.stock_employees[i].product.product_name,
          showConfirmButton: false,
          timer: 1500
        })
      }
    },

    editItem(id) {
      this.$router.push({name: 'pages-stock-employee-edit', params: {id: id}})
      // this.editedIndex = this.employees_types.indexOf(item)
      // this.editedItem = { ...item }
      // this.dialog = true
    },
    checkBill(item) {
      this.editedIndex = this.stock_employees.indexOf(item)
      this.editedItem = {...item}
      instance_stock_employee.show(item.id).then(res => {
        this.editedItem = res.data.stock;
        this.editedItem.stock_employees = res.data.data;
        this.dialog = true
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })

      })

    },
    deleteItem(item) {
      this.editedIndex = this.stock_employees.indexOf(item)
      this.editedItem = {...item}
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.$store.state.isLoading = true
      instance_stock_employee.stockEmployeeDestroy(this.editedItem.id).then(res => {

        setTimeout(function () {
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500
          })
        }, 150);
        this.getStockEmployee()
        this.closeDelete()
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false

      })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    cancel(id) {
      this.$store.state.isLoading = true
      instance_stock_employee.cancel(id).then(res => {
        Swal.fire({
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500
        })
        this.getStockEmployee()
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false

      });
    },
    save() {
    },
    getStockEmployee() {
      this.$store.state.isLoading = true
      var data = {
        search: this.search,
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        sortBy: this.sortBy ?? "",
        sortDesc: this.sortDesc ?? ''
      }

      instance_stock_employee.get(data).then(res => {
        this.$store.state.isLoading = false
        this.stock_employees = res.data.data.data;
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {

        this.$store.state.isLoading = false
      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}

.tr-danger {
  background-color: #f86c6b;
}

.tr-danger:active {
  background-color: #f86c6b;
}

.tr-danger:focus {
  background-color: #f86c6b;
}

.tr-danger:target {
  background-color: #f86c6b;
}

.tr-success {
  background-color: rgb(147, 196, 125)
}

.tr-success:active {
  background-color: rgb(147, 196, 125);
}
</style>
